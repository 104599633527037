import React, { useState, useEffect } from 'react'
import QRCode from 'react-qr-code'
import fetch from 'isomorphic-fetch'
import VCard from 'vcard-creator'
import { FaCircleNotch } from 'react-icons/fa'
import './ecard.scss'
import Helmet from 'react-helmet'

export default function eCard(props) {
  const { details, location } = props
  const { href } = location
  if (!details) return null
  const [data, setData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [showQrCode, setShowQrCode] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    fetchData()
  }, [])

  // Use async function to fetch data from the API
  function fetchData() {
    const ms = Date.now()
    const eCardsUrl =
      `${process.env.GATSBY_WP_ADDRESS}/wp-json/wp/v2/ecard/?slug=${details}&live=` +
      ms
    fetch(eCardsUrl)
      .then(response => response.json())
      .then(data => {
        const eCardData = data[0]
        const companyId = eCardData.company[0] || 95 // Set default company ID
        if (!eCardData || !companyId) {
          setLoading(false)
          return
        }
        const companyUrl =
          `${process.env.GATSBY_WP_ADDRESS}/wp-json/wp/v2/company/${companyId}?live=` +
          ms
        fetch(companyUrl)
          .then(response => response.json())
          .then(data => {
            const companyData = data
            eCardData.companyAcf = companyData.acf

            setData(eCardData)
            setLoading(false)
          })
          .catch(error => {
            console.error('Error', error)
            setLoading(false)
            setError(error)
          })
      })
      .catch(error => {
        console.error('Error', error)
        setLoading(false)
        setError(error)
      })
  }

  if (loading)
    return (
      <div className="ecard">
        <div className="inner">
          <div className="loading">
            <div>
              <FaCircleNotch className="spin" /> Loading...
            </div>
          </div>
        </div>
      </div>
    )

  if (!data || error)
    return (
      <div className="ecard">
        <div className="inner">No data found</div>
      </div>
    )

  const { title, acf, companyAcf } = data

  const {
    position,
    phone_direct,
    phone_mobile,
    phone_office,
    website,
    socials,
    email,
    address_link,
    address,
    address_line_two,
  } = acf

  const {
    logo,
    primary,
    secondary,
    default_phone,
    default_website,
    default_address,
    default_address_line_two,
    default_address_link,
    footer_text,
  } = companyAcf

  const office_phone = phone_office || default_phone
  const office_website = website || default_website
  const office_address = address || default_address
  const office_address_line_two =
    address_line_two || default_address_line_two || ''
  const office_address_link = address_link || default_address_link

  // Create vCard
  const CreateVCard = () => {
    const myVCard = new VCard()

    myVCard
      .addName(title.rendered.split())
      .addPhoneNumber(office_phone, 'DIRECT')
      .addPhoneNumber(phone_mobile, 'CELL')
      .addPhoneNumber(phone_office, 'WORK')
      .addEmail(email)
      .addAddress(office_address)
      .addURL(`https://${office_website}/`)

    return myVCard.toString()
  }

  // Save vCard to file
  const downloadTxtFile = vcfText => {
    const element = document.createElement('a')
    const file = new Blob([vcfText], { type: 'text/vcard;charset=utf-8' })
    element.href = URL.createObjectURL(file)
    element.download = `${title.rendered}.vcf`
    document.body.appendChild(element) // Required for this to work in FireFox
    element.click()
  }

  return (
    <div className="ecard">
      <Helmet>
        <style type="text/css">{`
          :root {
            --card-primary: ${primary};
            --card-secondary: ${secondary};
          }
         `}</style>
      </Helmet>
      <div className="inner">
        <button
          className={`share-button${showQrCode ? ' active' : ''}`}
          onClick={() => setShowQrCode(!showQrCode)}
        ></button>
        <div className={`back${showQrCode ? ' active' : ''}`}>
          <QRCode value={href} />
          <a href={href} className="preview-link">
            {href}
          </a>
        </div>
        <div className="front">
          <div className="logo">
            <img src={logo} />
          </div>

          <div className="name">
            <h1>{title.rendered}</h1>
            {position && <h2>{position}</h2>}
          </div>

          <div className="contact-details">
            {phone_direct && (
              <span>
                <i className="icon phone-direct" />
                {phone_direct}
              </span>
            )}

            {phone_mobile && (
              <span>
                <i className="icon phone-mobile" />
                {phone_mobile}
              </span>
            )}

            {office_phone && (
              <span>
                <i className="icon phone-office" />
                {office_phone}
              </span>
            )}

            {email && (
              <a href={`mailto: ${email}`}>
                <i className="icon email" />
                {email}
              </a>
            )}

            {office_website && (
              <a href={`https://${office_website}/`} target="__blank">
                <i className="icon website" />
                {office_website}
              </a>
            )}
          </div>

          {office_address && (
            <div className="address">
              <i className="icon address" />
              <a
                href={office_address_link}
                target="__blank"
                dangerouslySetInnerHTML={{
                  __html: `${office_address}<br/>${office_address_line_two}`,
                }}
              />
            </div>
          )}

          <button
            className="download"
            onClick={() => downloadTxtFile(CreateVCard())}
          >
            Download vCard
          </button>

          {socials && (
            <div className="socials">
              {socials.map((social, index) => (
                <a
                  key={index}
                  href={social.url}
                  className={`icon ${social.type}`}
                  target="__blank"
                />
              ))}
            </div>
          )}

          {footer_text && <div className="footer">{footer_text}</div>}
        </div>
      </div>
    </div>
  )
}
