import React from 'react'

import { Router } from '@reach/router'
import Ecard from '../../components/Ecard'

const eCardContent = () => {
  return (
    <Router>
      <Ecard path="/ecard/:details" />
    </Router>
  )
}

export default eCardContent
